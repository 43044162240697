<template>
  <form id="contact-form" action="#contact-form" @submit="onSubmit" class="form-row text-center">
    <div class="col-12 mb-3">
      <div class="form-container">
        <input
          type="text"
          :class="{'input-error': formErrors.full_name}"
          :placeholder="$lang('contactform.name', true)"
          v-model="form.full_name"
        />
      </div>
    </div>
    <div class="col-6 mb-3">
      <div class="form-container">
        <input
          type="text"
          :class="{'input-error': formErrors.email}"
          :placeholder="$lang('contactform.address', true)"
          v-model="form.email"
        />
      </div>
    </div>
    <div class="col-6 mb-3">
      <div class="form-container">
        <input
          type="text"
          :class="{'input-error': formErrors.phone}"
          :placeholder="$lang('contactform.phone', true)"
          v-model="form.phone"
        />
      </div>
    </div>
    <div class="col-12 mb-3">
      <FormSelect
        v-model="form.nationality"
        :label="$lang('contactform.label1', true)"
        :options="nationalities"
      />
    </div>
    <div class="col-12 mb-3" v-if="!download">
      <FormSelect
        v-model="form.country"
        :label="$lang('contactform.label2', true)"
        :options="countries"
      />
    </div>
    <div class="col-12 mb-3" v-if="!download">
      <FormSelect
        v-model="form.step"
        :label="$lang('contactform.label3', true)"
        :options="$lang('contactform.steps', true)"
      />
    </div>
    <div class="col-12 mb-3">
      <FormSelect
        v-model="form.program"
        :label="$lang('contactform.label5', true)"
        :options="$lang('contactform.programmes', true)"
      />
    </div>
    <div class="col-12 mb-3">
      <FormSelect
        v-model="form.campus"
        :label="$lang('contactform.label4', true)"
        :options="$lang('contactform.campuses', true)"
      />
    </div>
    <div class="col-12 mb-3" v-if="!download">
      <div class="form-container">
        <textarea
          cols="30"
          rows="5"
          :placeholder="$lang('contactform.message', true)"
          v-model="form.message"
        ></textarea>
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-blue-grad-1 btn-block" @click="sendForm">
        {{ $lang("contactform.button", true) }}
      </button>
    </div>
  </form>
</template>

<script>
import countries_es from "@/assets/json/countries-es.json";
import countries_en from "@/assets/json/countries-en.json";
import FormSelect from "@/components/Form/FormSelect";
import axios from "axios";
import subscribeToSendgrid from "../utils/sendgrid";

export default {
  props: {
    download: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormSelect,
  },
  data: () => ({
    form: {
      full_name: null,
      email: null,
      phone: null,
      nationality: "",
      country: "",
      step: "",
      program: "",
      campus: "",
      message: null,
    },
    formErrors: {
      full_name: false,
      email: false,
      phone: false,
    },
    countries_en,
    countries_es,
  }),
  computed: {
    countries() {
      return (this.$route.params.lang === "es" ? this.countries_es : this.countries_en).map(
        (x) => ({
          value: x.name,
          label: x.name,
        })
      );
    },
    nationalities() {
      return (this.$route.params.lang === "es" ? this.countries_es : this.countries_en).map(
        (x) => ({
          value: x.nationality,
          label: x.nationality,
        })
      );
    },
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();

      if (!this.validateForm()) return;

      await axios.get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
        params: {
          "your-name": this.form.full_name,
          "your-email": this.form.email,
          "your-phone": this.form.phone,
          "your-country": this.form.country,
          "your-nationality": this.form.nationality,
          "your-step": this.form.step,
          "your-program": this.form.program,
          "your-campus": this.form.campus,
          "your-message": this.form.message,
          token: "dok6gj1162jbeey015if017389y12b9",
        },
      });

      if (this.download) this.downloadBrochure();

      await subscribeToSendgrid({
        name: this.form.full_name,
        email: this.form.email,
        sendgridToken: "a1962f02-8dfa-43af-9410-be03777eac2e",
      });

      this.clearForm();

      this.formSuccessMessage();

    },
    validateForm() {
      let isValid = true;
      this.formErrors.full_name = false;
      this.formErrors.email = false;
      this.formErrors.phone = false;

      const regexName = /[^a-zA-Z\s]/;
      const emailRegex = /^\S+@\S+\.\S+$/;
      const phoneRegex = /^\+?[0-9\s]+$/;


      if(!this.form.full_name || this.form.full_name.length < 3 || regexName.test(this.form.full_name)){
        console.log('invalid name')
        isValid = false;
        this.formErrors.full_name = true;
      }
      if(!this.form.email || this.form.email.length < 3 || !emailRegex.test(this.form.email)){
        isValid = false;
        this.formErrors.email = true;
      }
      if(!this.form.phone || this.form.phone.length < 6 || !phoneRegex.test(this.form.phone)){
        isValid = false;
        this.formErrors.phone = true;
      }
      return isValid;
    },
    clearForm() {
      this.form = {
        full_name: null,
        email: null,
        phone: null,
        nationality: "",
        country: "",
        step: "",
        program: "",
        campus: "",
        message: null,
      };
    },
    formSuccessMessage() {
      this.$toast.success(this.$lang("contactform.success_msg", true));

      this.$emit("onSent");
    },
    downloadBrochure() {
      // get brochure and download
      const a = document.createElement("a");
      a.id = "brochure_download";
      a.href = "/files/brochure.pdf";
      a.download = "brochure.pdf";
      a.target = "_blank";
      a.click();
    },
  }
};
</script>

<style>
.input-error::placeholder {
  color: red;
}
.input-error {
  color: red;
}
</style>
